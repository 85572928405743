import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { BehaviorSubject, Observable, of } from 'rxjs';
// import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of, map, tap, catchError, from } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUserAxen: Observable<User>;
  public loggedIn: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private http: HttpClient) {      
    this.loggedIn = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUserAxen = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate`, {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }


  // constructor(
  //   private router: Router
  // ) {
  //   this.loggedIn = new BehaviorSubject<boolean>(false);
  // }

  /** signup */
  public signUp(email:string, password:string): Observable<any> {
    return from(Auth.signUp(email, password));
  }

  /** confirm code */
  public confirmSignUp(email:string, code:string): Observable<any> {
    return from(Auth.confirmSignUp(email, code));
  }

  /** signin */
  public signIn(email:string, password:string): Observable<any> {
    return from(Auth.signIn(email, password))
      .pipe(
        tap(() => this.loggedIn.next(true))
      );
  }

  /** get authenticat state */
  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  /** signout */
  public signOut() {
    from(Auth.signOut())
      .subscribe(
        result => {
          this.loggedIn.next(false);
          this.router.navigate(['/authentication']);
        },
        error => console.log(error)
      );
  }

  public forgotPassword(email:string){
    return from(Auth.forgotPassword(email));
  }

  public recoverAccess(email:string, code:string, new_password:string){
    return from(Auth.forgotPasswordSubmit(email, code, new_password));
  }

  public currentUser(): Observable<any> {
    return from(Auth.currentAuthenticatedUser({bypassCache: false}));
  }

}
