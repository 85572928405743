import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { LoggedInAuthGuard } from './core/guard/loggedIn.guard';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: "organization",
        loadChildren: () =>
          import("./organization/organization.module").then(
            (m) => m.OrganizationModule
          ),
      },
      {
        path: "diagnostic",
        loadChildren: () =>
          import("./diagnostic/diagnostic.module").then(
            (m) => m.DiagnosticModule
          ),
      },
      {
        path: "quiz",
        loadChildren: () =>
          import("./quiz/quiz.module").then(
            (m) => m.QuizModule
          ),
      },
      // {
      //   path: "load",
      //   loadChildren: () =>
      //     import("./load/load.module").then(
      //       (m) => m.LoadModule
      //     ),
      // },
      // {
      //   path: 'extra-pages',
      //   loadChildren: () =>
      //     import('./extra-pages/extra-pages.module').then(
      //       (m) => m.ExtraPagesModule
      //     ),
      // },
      // {
      //   path: 'multilevel',
      //   loadChildren: () =>
      //     import('./multilevel/multilevel.module').then(
      //       (m) => m.MultilevelModule
      //     ),
      // },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
